export const APP_VERSION = "6.155.0";
export const APP_NAME = "lawnlove-customer-web";
export const BRAND_SLUG = "lawnlove";
export const GOOGLE_API_KEY = "AIzaSyCtW4KAA_kr-LPbnH6EMPwOsnjvxqXTVuk";
export const GOOGLE_FIREBASE_SENDER_ID = "301943725061";
export const CANCELLATION_SUPPORT_PHONE_NUMBER = "1-855-577-9573";
export const CANCELLATION_ZENDESK_WEB_CHANNEL_KEY = "da7e116a-6a38-4ff0-929f-2e24a1b6e1dd";
export const CANCELLATION_ZENDESK_IOS_CHANNEL_KEY = "eyJzZXR0aW5nc191cmwiOiJodHRwczovL2xhd25zdGFydGVyMS56ZW5kZXNrLmNvbS9tb2JpbGVfc2RrX2FwaS9zZXR0aW5ncy8wMUoyQ0gyN1ZIOERWRzdIM1lIUjZWMTIwWS5qc29uIn0=";
export const CANCELLATION_ZENDESK_ANDROID_CHANNEL_KEY = "eyJzZXR0aW5nc191cmwiOiJodHRwczovL2xhd25zdGFydGVyMS56ZW5kZXNrLmNvbS9tb2JpbGVfc2RrX2FwaS9zZXR0aW5ncy8wMUoyQ0g4MVhKRkVOWEZYTk4xMUFHUFQ0Vi5qc29uIn0=";
export const ZENDESK_IOS_CHANNEL_KEY = "eyJzZXR0aW5nc191cmwiOiJodHRwczovL2xhd25zdGFydGVyMS56ZW5kZXNrLmNvbS9tb2JpbGVfc2RrX2FwaS9zZXR0aW5ncy8wMUg5M1ZFV0JOTTVQU1ZGS0RCSlhHU1dSQy5qc29uIn0=";
export const ZENDESK_ANDROID_CHANNEL_KEY = "eyJzZXR0aW5nc191cmwiOiJodHRwczovL2xhd25zdGFydGVyMS56ZW5kZXNrLmNvbS9tb2JpbGVfc2RrX2FwaS9zZXR0aW5ncy8wMUg5M1ZKMjhRUDNGOEg1N1RWR1pBMVlXMy5qc29uIn0=";
export const ZENDESK_WEB_CHANNEL_KEY = "5b31e0ab-e39e-4513-a013-f79c662d05ff";
export const BRAZE_ENDPOINT = "sdk.iad-05.braze.com";
export const SHARE_ON_NEXTDOOR_TIMEOUT_MINUTES = "5";
export const ENV = "green";
export const SENTRY_ENVIRONMENT = "green";
export const SENTRY_PROJECT_SLUG = "https://7a64fe4d24c140af9099be12c5818127@sentry.io/1367049";
export const CUST_WEB_BASE_URL = "https://green-my.lawnlove.com";
export const API_BASE_URL = "https://green-api.lawnlove.com";
export const SIGNUP_BASE_URL = "https://green-signup.lawnlove.com";
export const PARTNERS_BASE_URL = "https://green-partners-api.lawnstarter.com";
export const SEGMENT_KEY = "zby7S30gFG80iVKWtCUxMurdUBZwQvK5";
export const STRIPE_KEY = "pk_test_Gwo1qUvFZUQrufioqsESkESd";
export const LEGACY_CUST_WEB_BASE_URL = "https://green-legacy-my.lawnlove.com";
export const FLAGSMITH_ENVIRONMENT_KEY = "XySXXtqt5UfgpiNtWgfXiC";